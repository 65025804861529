import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Tooltip
} from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import { DateTime } from 'luxon';

const InterstitialsList = ({ data, isRequesting, onInterstitialEdit, onInterstitialDelete, onInterstitialPublishUpdate }) => {
  const isPublishable = (start, end) => {
    const startDateTime = DateTime.fromISO(start);
    const endDateTime = DateTime.fromISO(end);

    const now = DateTime.now();

    if (startDateTime < now && endDateTime > now) {
      return true;
    }

    return false;
  };

  return (
    <PerfectScrollbar>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="tablecell-green">Titre</TableCell>
              <TableCell className="tablecell-green">Date de début</TableCell>
              <TableCell className="tablecell-green">Date de fin</TableCell>
              <TableCell className="tablecell-green">Prioritaire</TableCell>
              <TableCell className="tablecell-green" style={{ width: '250px' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {!isRequesting ? (
            <TableBody>
              {data.map(
                ({
                  id,
                  title,
                  start,
                  end,
                  time,
                  redirect,
                  infoTrafic,
                  externalUrl,
                  backgroundColor,
                  priority,
                  imageId,
                  imageUrl,
                  published
                }) => (
                  <TableRow hover key={id}>
                    <TableCell>{title}</TableCell>
                    <TableCell>{DateTime.fromISO(start).toFormat('dd/MM/yyyy HH:mm')}</TableCell>
                    <TableCell>{DateTime.fromISO(end).toFormat('dd/MM/yyyy HH:mm')}</TableCell>
                    <TableCell>
                      <Checkbox disabled checked={priority > 0} />
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Editer l'interstitiel">
                        <Button
                          startIcon={<EditIcon />}
                          href="#text-buttons"
                          color="primary"
                          onClick={() =>
                            onInterstitialEdit({
                              id,
                              title,
                              start,
                              end,
                              time,
                              redirect,
                              infoTrafic,
                              externalUrl,
                              backgroundColor,
                              priority,
                              imageId,
                              imageUrl
                            })
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Supprimer l'interstitiel">
                        <Button
                          startIcon={<DeleteOutlineIcon />}
                          color="secondary"
                          onClick={() => onInterstitialDelete({ id, title, redirect })}
                        />
                      </Tooltip>
                      {isPublishable(start, end) && (
                        <Tooltip title={published === 0 ? "Publier l'interstitiel" : "Dépublier l'interstitiel"}>
                          <Button
                            startIcon={published === 1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            color="primary"
                            onClick={() => onInterstitialPublishUpdate({ id, title, published, redirect })}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow hover>
                <TableCell colSpan={11} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

InterstitialsList.propTypes = {
  data: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool,
  onInterstitialDelete: PropTypes.func,
  onInterstitialEdit: PropTypes.func,
  onInterstitialPublishUpdate: PropTypes.func
};

export default InterstitialsList;
