import { Get, Post, Put } from './network.service';

export const getMiddlewareData = () => {
  return Get('middleware/settings');
};

export const getStatusImports = () => {
  return Get('middleware/last-x-days');
};

export const updateRealTimeMode = (mode, label) => {
  return Put('middleware/settings/real-time', {
    mode,
    label
  });
};
export const updateTheoricTimeMode = (mode, label) => {
  return Put('middleware/settings/theoric-time', {
    mode,
    label
  });
};

export const updateSubsidiaryTimeMode = (config) => {
  return Put('middleware/settings/subsidiary-time', config);
};

export const resyncAllMain = () => {
  return Post('middleware/imports/allSPOTI3', null);
};

/**
 *
 * @param {string[]} agenciesIds
 * @returns
 */
export const resyncSubsidiariesByIds = (agenciesIds) => {
  return Post('middleware/imports/subsidiaries', { agencies: agenciesIds });
};

/**
 *
 * @param {string[]} agenciesIds
 * @returns
 */
export const getAllSubsidiaries = () => {
  return Get('middleware/subsidiaries');
};
