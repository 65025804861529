import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CompressIcon from '@mui/icons-material/Compress';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

const LinesList = ({ data, isRequesting, onEdit, onDelete, onReorderLine }) => {
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    onReorderLine(e.draggableId, e.destination.index);
  };

  return (
    <PerfectScrollbar>
      <Box sx={{ minWidth: 800 }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tablecell-green">#</TableCell>
                <TableCell className="tablecell-green">Ordre</TableCell>
                <TableCell className="tablecell-green">Code</TableCell>
                <TableCell className="tablecell-green">Nom</TableCell>
                <TableCell className="tablecell-green">Couleur</TableCell>
                <TableCell className="tablecell-green">Visible</TableCell>
                <TableCell className="tablecell-green" style={{ width: '250px' }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {!isRequesting ? (
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                    {data.map(({ id, code, name, color, type, order, enabled }, index) => (
                      <Draggable key={id} draggableId={id} index={index}>
                        {(provider2) => (
                          <TableRow
                            key={id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            {...provider2.draggableProps}
                            ref={provider2.innerRef}
                          >
                            <TableCell {...provider2.dragHandleProps}>
                              <CompressIcon />
                            </TableCell>
                            <TableCell>{order}</TableCell>
                            <TableCell>{code}</TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  flexDirection: 'column'
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div
                                    style={{
                                      height: '40px',
                                      width: '40px',
                                      margin: '10px',
                                      borderRadius: '3px',
                                      border: 'thin solid black',
                                      background: color
                                    }}
                                  />
                                  {color}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>{enabled ? <VisibilityIcon /> : <VisibilityOffIcon />}</TableCell>
                            <TableCell>
                              <Button
                                startIcon={<EditIcon />}
                                href="#text-buttons"
                                color="primary"
                                onClick={() => onEdit({ id, name, color, type, order, enabled, code })}
                              >
                                Éditer
                              </Button>
                              <Button startIcon={<DeleteOutlineIcon />} color="secondary" onClick={() => onDelete({ id, name })}>
                                Supprimer
                              </Button>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </TableBody>
                )}
              </Droppable>
            ) : (
              <TableBody>
                <TableRow hover>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </DragDropContext>
      </Box>
    </PerfectScrollbar>
  );
};

LinesList.propTypes = {
  data: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onReorderLine: PropTypes.func
};

export default LinesList;
