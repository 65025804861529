import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { ThemeProvider, Snackbar } from '@mui/material';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import LoadConfig from './services/load-config.service';
import appConfig from './assets/config.json';
import AppContext from './contexts/AppContext';

const App = () => {
  const routing = useRoutes(routes);
  const [configLoaded, setconfigLoaded] = useState(false);

  const [message, setMessage] = useState({});

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage({});
  };

  useEffect(() => {
    LoadConfig()
      .then((res) => {
        window.API_URL = res.data.baseUrl;
        window.SHOW_DEV = res.data.showDev === true;
      })
      .catch(() => {
        window.API_URL = appConfig.baseUrl;
        window.SHOW_DEV = appConfig.showDev === true;
      })
      .finally(() => {
        setconfigLoaded(true);
      });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr">
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppContext.Provider value={{ setMessage }}>
          <Snackbar
            ContentProps={{
              sx: {
                background: message.type === 'error' ? 'red' : 'green'
              }
            }}
            open={message.text}
            autoHideDuration={4000}
            onClose={handleMessageClose}
            message={message.text}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          {configLoaded && routing}
        </AppContext.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
