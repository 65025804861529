import { useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material';
import PropTypes from 'prop-types';

const BDDTransferForm = ({ onGoing, handleTransfer }) => {
  const [openConfirm, setOpenConfirm] = useState(false);

  /**
   * Handle confirmation dialog closing event
   */
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  return (
    <>
      <Card>
        <CardHeader subheader="Transfert de la base de données mobiles" title="Init BDD" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                Vous pouvez dans cet écran transférer la base de données de pré-production en production.
              </Alert>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button color="primary" variant="contained" onClick={handleOpenConfirm} disabled={onGoing}>
            Transférer
          </Button>
        </Box>
      </Card>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Etes-vous sur de vouloir mettre à jour l&apos;environnement de production?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Annuler</Button>
          <Button
            onClick={(event) => {
              handleCloseConfirm(event);
              handleTransfer();
            }}
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

BDDTransferForm.propTypes = {
  onGoing: PropTypes.bool,
  handleTransfer: PropTypes.func
};

export default BDDTransferForm;
