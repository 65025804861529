import React, { useState } from 'react';

import { TableCell, TableRow, Button } from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import PropTypes from 'prop-types';
import LinesList from './LinesList';

const LineTypeTableRow = ({ lineType, onLineTypeEdit, onLineTypeDelete, onLineEdit, onLineDelete, onReorderLine }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpander = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const renderMainRow = () => {
    return (
      <TableRow hover key={lineType.id}>
        <TableCell onClick={toggleExpander}>{lineType.order}</TableCell>
        <TableCell onClick={toggleExpander}>{lineType.code}</TableCell>
        <TableCell onClick={toggleExpander}>{lineType.name}</TableCell>
        <TableCell onClick={toggleExpander}>{lineType.enabled ? <VisibilityIcon /> : <VisibilityOffIcon />}</TableCell>
        <TableCell>
          <Button startIcon={<EditIcon />} href="#text-buttons" color="primary" onClick={() => onLineTypeEdit(lineType)}>
            Éditer
          </Button>
          <Button
            startIcon={<DeleteOutlineIcon />}
            color="secondary"
            onClick={() => onLineTypeDelete({ id: lineType.id, name: lineType.name })}
          >
            Supprimer
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  const renderCollapseRow = () => {
    if (!expanded) return null;
    return (
      <TableRow hover key="{id}-expander">
        <TableCell colSpan={11}>
          {lineType.lines.length ? (
            <LinesList data={lineType.lines} onEdit={onLineEdit} onDelete={onLineDelete} onReorderLine={onReorderLine} />
          ) : (
            <TableCell colSpan={11} align="center">
              Pas de ligne pour ce type.
            </TableCell>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return [renderMainRow(), renderCollapseRow()];
};

LineTypeTableRow.propTypes = {
  lineType: PropTypes.object.isRequired,
  onLineEdit: PropTypes.func,
  onLineDelete: PropTypes.func,
  onReorderLine: PropTypes.func
};

export default LineTypeTableRow;
