import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, Dialog } from '@mui/material';
import ConfirmPublish from 'src/components/lines/ConfirmPublish';
import HintsList from '../components/hints/HintsList';
import HintForm from '../components/hints/HintForm';
import ConfirmDelete from '../components/lines/ConfirmDelete';
import { isAutenticated } from '../services/login.service';
import { getHints, deleteHint, updatePublishHint, reorderHint } from '../services/hint.service';
import { getCategories } from '../services/category.service';

const HintsPage = () => {
  const navigate = useNavigate();
  const [hints, setHints] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedHint, setSelectedHint] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const [openConfirmationDeleteDialog, setOpenConfirmationDeleteDialog] = useState(false);
  const [openConfirmationPublishDialog, setOpenConfirmationPublishDialog] = useState(false);

  const [isRequesting, setisRequeting] = useState(false);
  const [modalStyle] = useState({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

  const loadHints = async () => {
    setisRequeting(true);

    const hintsRes = await getHints();
    const { data } = hintsRes;
    setHints([...data]);

    const categoriesRes = await getCategories();
    setCategories(categoriesRes.data);

    setisRequeting(false);
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadHints();
    } else {
      navigate('/login');
    }
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
    setOpenConfirmationDeleteDialog(false);
    setOpenConfirmationPublishDialog(false);
  };

  const openCreateHint = () => {
    setSelectedHint(null);
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setOpenConfirmationDeleteDialog(false);
    setOpenConfirmationPublishDialog(false);
  };

  const onReorderHint = (hintId, afterHintId) => {
    reorderHint(hintId, afterHintId).then(() => {
      loadHints();
    });
  };

  return (
    <>
      <Helmet>
        <title>RTM - Astuces</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button sx={{ margin: '10px' }} color="primary" variant="contained" onClick={openCreateHint}>
                Ajouter une astuce
              </Button>
            </Box>
          </Box>

          <Box sx={{ pt: 3 }}>
            <HintsList
              data={hints}
              isRequesting={isRequesting}
              onHintEdit={(hintToEdit) => {
                setSelectedHint(hintToEdit);
                setOpenDialog(true);
              }}
              onHintDelete={(hintToDelete) => {
                setSelectedHint(hintToDelete);
                setOpenConfirmationDeleteDialog(true);
                setOpenDialog(true);
              }}
              onHintPublishUpdate={(hintToPublish) => {
                setSelectedHint(hintToPublish);
                setOpenConfirmationPublishDialog(true);
                setOpenDialog(true);
              }}
              onReorderHint={onReorderHint}
            />
            <Dialog open={openDialog} onClose={handleClose} style={modalStyle} maxWidth={false} disableEnforceFocus>
              {openConfirmationPublishDialog && (
                <ConfirmPublish
                  name={selectedHint.title}
                  onClose={(publishChange) => {
                    if (publishChange) {
                      updatePublishHint(selectedHint.id, selectedHint.published ? 0 : 1).then(() => {
                        closeDialog();
                        loadHints();
                      });
                    } else {
                      closeDialog();
                    }
                  }}
                />
              )}
              {openConfirmationDeleteDialog && (
                <ConfirmDelete
                  name={selectedHint.title}
                  onClose={(isDelete) => {
                    if (isDelete) {
                      deleteHint(selectedHint.id).then(() => {
                        closeDialog();
                        loadHints();
                      });
                    } else {
                      closeDialog();
                    }
                  }}
                />
              )}
              {!openConfirmationDeleteDialog && !openConfirmationPublishDialog && (
                <HintForm
                  hint={selectedHint}
                  categories={categories}
                  onClose={(isRefresh) => {
                    closeDialog();
                    if (isRefresh) {
                      loadHints();
                    }
                  }}
                />
              )}
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default HintsPage;
