import { useState, useEffect, useContext } from 'react';
import { Box, Card, CardContent, CardHeader, Dialog, Divider, Grid, TextField } from '@mui/material';
import MessageSuccess from 'src/components/flux/MessageSuccess';

import {
  getMiddlewareData,
  updateRealTimeMode,
  updateSubsidiaryTimeMode,
  updateTheoricTimeMode
} from 'src/services/middleware.service';
import ActionListBody from 'src/components/flux/ActionListBody';
import getActionByPaginate from 'src/services/action.service';
import AppContext from 'src/contexts/AppContext';

const subsidiaryTtTimeOptions = [
  {
    value: 'DB_CACHE',
    label: 'Cache local'
  },
  {
    value: 'OFF',
    label: 'AUCUN'
  }
];

const subsidiaryRtTimeOptions = [
  {
    value: 'DB_CACHE',
    label: 'Temps Théorique'
  },
  {
    value: 'OFF',
    label: 'AUCUN'
  }
];

const theoricTimeOptions = [
  {
    value: 'SPOTI3',
    label: 'SPOTI3'
  },
  {
    value: 'DB_CACHE',
    label: 'Cache local'
  },
  {
    value: 'GTFS',
    label: 'GTFS'
  }
];

const realTimeOptions = [
  {
    value: 'SPOTI3',
    label: 'SPOTI3'
  },
  {
    value: 'INT_MAP',
    label: 'Carte interactive'
  },
  {
    value: 'OFF',
    label: 'AUCUN'
  },
  {
    value: 'DB_CACHE',
    label: 'Temps Théorique'
  }
];

const FluxForm = (props) => {
  // Get AppContext
  const contextProps = useContext(AppContext);

  const { setMessage } = contextProps;

  const [values, setValues] = useState({
    currentTheoricTimeMode: '',
    currentRealTimeMode: '',
    theoricTimeMode: '',
    realTimeMode: ''
  });
  const [subsidiaries, setGTFS] = useState([]);
  const [openMessage, setopenMessage] = useState(false);
  const [modalStyle] = useState({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

  const [actions, setactions] = useState([]);
  const [count, setcount] = useState(0);
  const [isRequesting, setisRequeting] = useState(false);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const loadAction = (pageNumber, pageLimit) => {
    setisRequeting(true);

    getActionByPaginate(pageNumber, pageLimit).then(
      (res) => {
        const { data } = res;
        setcount(data.pageMax);
        setactions(data.data);
        setisRequeting(false);
      },
      () => {
        setisRequeting(false);
      }
    );
  };

  useEffect(() => {
    loadAction(1, 10);
  }, [values]);

  const handleClose = () => {
    setopenMessage(false);
  };

  useEffect(() => {
    getMiddlewareData()
      .then((res) => {
        const { theoricTimeMode, realTimeMode, gtfsTimeModes } = res.data;
        setValues({
          currentTheoricTimeMode: theoricTimeMode,
          currentRealTimeMode: realTimeMode,
          realTimeMode,
          theoricTimeMode
        });

        setGTFS(gtfsTimeModes);
      })
      .catch(() => {
        setMessage({ type: 'error', text: 'Erreur de récupération de la configuration' });
      });
  }, []);

  const handleChangeRealtimeMode = (event) => {
    const newRealtimeMode = event.target.value;

    const realTimeOption = realTimeOptions.find(({ value }) => value === newRealtimeMode);

    if (newRealtimeMode !== values.currentRealTimeMode) {
      updateRealTimeMode(newRealtimeMode, realTimeOption.label)
        .then(() => {
          setMessage({ text: 'Configuration realtime mise à jour' });

          // Set new value as new references
          setValues({
            ...values,
            currentRealTimeMode: newRealtimeMode,
            realTimeMode: newRealtimeMode
          });
        })
        .catch(() => {
          setMessage({ type: 'error', text: 'Erreur de mise à jour de la configuration realtime' });
        });
    }
  };

  const handleChangeTheoricTimeMode = (event) => {
    const newTheoricTimeMode = event.target.value;

    const theoricTimeOption = theoricTimeOptions.find(({ value }) => value === newTheoricTimeMode);

    if (newTheoricTimeMode !== values.currentTheoricTimeMode) {
      updateTheoricTimeMode(newTheoricTimeMode, theoricTimeOption.label)
        .then(() => {
          setMessage({ text: 'Configuration temps théorique mise à jour' });

          // Set new value as new references
          setValues({
            ...values,
            currentTheoricTimeMode: newTheoricTimeMode,
            theoricTimeMode: newTheoricTimeMode
          });
        })
        .catch(() => {
          setMessage({ type: 'error', text: 'Erreur de mise à jour de la configuration temps théorique' });
        });
    }
  };

  const handleChangeSubsidiaryTimeMode = (event, mode, subsidiary) => {
    const newSubsidiaryTimeMode = event.target.value;
    updateSubsidiaryTimeMode({
      modeTT: mode === 'TT' ? newSubsidiaryTimeMode : subsidiary.modeTT,
      modeRT: mode === 'RT' ? newSubsidiaryTimeMode : subsidiary.modeRT,
      label: subsidiary.label,
      agencyId: subsidiary.agencyId
    })
      .then(() => {
        setMessage({ text: 'Configuration temps gtfs mise à jour' });
        // Set new value as new references

        const newsub = subsidiaries.map((subsidiaryElement) => {
          if (subsidiaryElement.agencyId === subsidiary.agencyId) {
            return {
              modeTT: mode === 'TT' ? newSubsidiaryTimeMode : subsidiary.modeTT,
              modeRT: mode === 'RT' ? newSubsidiaryTimeMode : subsidiary.modeRT,
              label: subsidiary.label,
              agencyId: subsidiary.agencyId
            };
          }
          return subsidiaryElement;
        });

        setGTFS(newsub);
      })
      .catch(() => {
        setMessage({ type: 'error', text: 'Erreur de mise à jour de la configuration temps GTFS' });
      });
  };

  return (
    <>
      <form autoComplete="off" noValidate {...props}>
        <Card style={{ marginBottom: 20 }}>
          <CardHeader className="tablecell-green" subheader="Choix des flux TR/TT" title="Flux RTM et lecar (anciennes apps)" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Mode Temps Réel actuel"
                  name="currentRealTimeMode"
                  select
                  disabled
                  SelectProps={{ native: true }}
                  value={values.currentRealTimeMode}
                  variant="outlined"
                >
                  {realTimeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nouveau mode Temps Réel"
                  name="realTimeMode"
                  onChange={handleChangeRealtimeMode}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.realTimeMode}
                  variant="outlined"
                >
                  {realTimeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Mode Temps Théorique actuel"
                  name="currentTheoricTimeMode"
                  select
                  disabled
                  SelectProps={{ native: true }}
                  value={values.currentTheoricTimeMode}
                  variant="outlined"
                >
                  {theoricTimeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nouveau mode Temps Théorique"
                  name="theoricTimeMode"
                  onChange={handleChangeTheoricTimeMode}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.theoricTimeMode}
                  variant="outlined"
                >
                  {theoricTimeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {subsidiaries.map((subsidiary) => (
          ((subsidiary.agencyId === 'RTM' && values.theoricTimeMode === 'GTFS') || subsidiary.agencyId !== 'RTM') &&
          <Card style={{ marginBottom: 20 }} key={subsidiary.agencyId}>
            <CardHeader className="tablecell-green" subheader="Choix des flux TR/TT" title={`Flux GTFS ${subsidiary.label}`} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                {subsidiary.agencyId !== 'RTM' &&
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Mode Temps Réel actuel"
                      name="currentSubsidiaryRtTimeMode"
                      select
                      disabled
                      SelectProps={{ native: true }}
                      value={subsidiary.modeRT}
                      variant="outlined"
                    >
                      {subsidiaryRtTimeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                }
                {subsidiary.agencyId !== 'RTM' &&
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Nouveau mode Temps Réel"
                      name="newsSubsidiaryRtTimeMode"
                      onChange={($event) => handleChangeSubsidiaryTimeMode($event, 'RT', subsidiary)}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={subsidiary.modeRT}
                      variant="outlined"
                    >
                      {subsidiaryRtTimeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                }
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Mode Temps Théorique actuel"
                    name="currentSubsidiaryTtTimeMode"
                    select
                    disabled
                    SelectProps={{ native: true }}
                    value={subsidiary.modeTT}
                    variant="outlined"
                  >
                    {subsidiaryTtTimeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Nouveau mode Temps Théoriques"
                    name="newSubsidiaryTtTimeMode"
                    onChange={($event) => handleChangeSubsidiaryTimeMode($event, 'TT', subsidiary)}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={subsidiary.modeTT}
                    variant="outlined"
                  >
                    {subsidiaryTtTimeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </form>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      />

      <Card style={{ flex: 1, width: 1000 }}>
        <CardHeader className="tablecell-green" subheader="Liste des actions" title="Actions" />
        <Divider />
        <CardContent>
          <ActionListBody
            data={actions}
            count={count}
            onPaginate={(p, l) => loadAction(p, l)}
            isRequesting={isRequesting}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
          />
        </CardContent>
      </Card>

      <Dialog open={openMessage} onClose={handleClose} style={modalStyle}>
        <MessageSuccess
          onClose={() => {
            setopenMessage(false);

            window.location.reload(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default FluxForm;
