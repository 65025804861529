import { useContext, useState, useRef, useEffect } from 'react';
import AppContext from 'src/contexts/AppContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, MenuItem, Fab } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GlobalStyles from '@mui/material/GlobalStyles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { encode, decode } from 'html-entities';

import { createHint, updateHint, uploadHintImage } from '../../services/hint.service';
import placeholder from '../../assets/placeholder-image.png';

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      '.ck.ck-balloon-panel': {
        zIndex: '9999',
      },
    }}
  />
);

const HintForm = ({ hint, categories, onClose }) => {
  // Get AppContext
  const contextProps = useContext(AppContext);
  // const entities = new Entities.AllHtmlEntities();

  const { setMessage } = contextProps;

  const formik = useRef();

  const [androidImageUrl, setAndroidImageUrl] = useState('');
  const [iosImageUrl, setIOSImageUrl] = useState('');

  const uploadAndroidImageRef = useRef();
  const uploadIOSImageRef = useRef();

  useEffect(() => {
    if (hint) {
      setAndroidImageUrl(hint.androidImageUrl);
      formik.current.values.hintAndroidImage = hint.androidImageUrl;

      setIOSImageUrl(hint.iosImageUrl);
      formik.current.values.hintIOSImage = hint.iosImageUrl;
    }
  }, [hint]);

  const onAndroidImageUpload = () => {
    uploadHintImage(uploadAndroidImageRef.current.files[0]).then((uploadResponse) => {
      setAndroidImageUrl(uploadResponse.data.url);
      formik.current.values.hintAndroidImage = uploadResponse.data.url;
    });
  };

  const onIOSImageUpload = () => {
    uploadHintImage(uploadIOSImageRef.current.files[0]).then((uploadResponse) => {
      setIOSImageUrl(uploadResponse.data.url);
      formik.current.values.hintIOSImage = uploadResponse.data.url;
    });
  };

  return (
    <Formik
      innerRef={formik}
      initialValues={{
        title: hint ? hint.title : '',
        version: hint ? hint.version : '',
        categoryId: hint ? hint.categoryId : '',
        categoryLabel: hint ? hint.categoryLabel : '',
        hintAndroidImage: undefined,
        hintIOSImage: undefined,
        description: hint ? decode(hint.description) : ''
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('Le titre est obligatoire'),
        version: Yup.string().required('La version est obligatoire'),
        categoryId: Yup.string().required('La catégorie est obligatoire'),
        hintAndroidImage: Yup.string().required("L'image Android est obligatoire"),
        hintIOSImage: Yup.string().required("L'image iOS est obligatoire"),
        description: Yup.string().required('La description est obligatoire')
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);

        const { title, version, categoryId, description } = values;

        // Find selected category
        const categorySelected = categories.find((category) => categoryId === category.id);
        if (hint) {
          updateHint(hint.id, {
            title,
            androidImageUrl,
            iosImageUrl,
            version,
            categoryId,
            categoryLabel: categorySelected.label,
            description: encode(description, { mode: 'nonAsciiPrintableOnly' })
          })
            .then(() => {
              setSubmitting(false);
              onClose(true);
            })
            .catch((e) => {
              if (axios.isAxiosError(e)) {
                setMessage({
                  type: 'error',
                  text: `Erreur lors de la mise à jour de l'astuce : ${e.response.data.message}`
                });
              } else {
                setMessage({ type: 'error', text: "Erreur lors de la mise à jour de l'astuce" });
              }
            });
        } else {
          createHint({
            title,
            androidImageUrl,
            iosImageUrl,
            version,
            categoryId,
            categoryLabel: categorySelected.label,
            description
          })
            .then(() => {
              setSubmitting(false);
              onClose(true);
            })
            .catch((e) => {
              if (axios.isAxiosError(e)) {
                setMessage({
                  type: 'error',
                  text: `Erreur lors de la création de de l'astuce : ${e.response.data.message}`
                });
              } else {
                setMessage({ type: 'error', text: "Erreur lors de la création de la l'astuce" });
              }
            });
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue, validateForm }) => (
        <form
          autoComplete="off"
          noValidate
          style={{
            width: '100%'
          }}
        >
          <Card sx={{ maxWidth: 780 }}>
            <CardHeader subheader={hint ? "Modification d'une astuce" : 'Créer une nouvelle astuce'} title="Astuce" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    label="Titre"
                    margin="normal"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.title}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={6}>
                  <Grid
                    item
                    style={{
                      border: !values.hintAndroidImage && errors.hintAndroidImage ? '#d32f2f solid 1px' : '',
                      borderRadius: 5,
                      padding: 10
                    }}
                  >
                    <img
                      src={androidImageUrl || placeholder}
                      alt="hintAndroidImage"
                      width="300px"
                      height="150px"
                      style={{ display: 'block', objectFit: 'contain' }}
                    />
                    <label htmlFor="android-button-image-file">
                      <input
                        name="hintAndroidImage"
                        accept="*/*"
                        style={{ display: 'none' }}
                        id="android-button-image-file"
                        type="file"
                        onBlur={handleBlur}
                        onChange={onAndroidImageUpload}
                        ref={uploadAndroidImageRef}
                      />
                      <Fab size="small" component="span" aria-label="add">
                        <FileUploadIcon />
                      </Fab>
                    </label>
                  </Grid>
                  {errors.hintAndroidImage && !values.hintAndroidImage && (
                    <p
                      style={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: 1.66,
                        letterSpacing: '0.03333em',
                        textAlign: 'left',
                        marginTop: '3px',
                        marginRight: '14px',
                        marginBottom: 0,
                        marginLeft: '14px',
                        color: '#d32f2f'
                      }}
                    >
                      {errors.hintAndroidImage}
                    </p>
                  )}
                </Grid>

                <Grid item md={6} xs={6}>
                  <Grid
                    item
                    style={{
                      border: !values.hintIOSImage && errors.hintIOSImage ? '#d32f2f solid 1px' : '',
                      borderRadius: 5,
                      padding: 10
                    }}
                  >
                    <img
                      src={iosImageUrl || placeholder}
                      alt="hintIOSImage"
                      width="300px"
                      height="150px"
                      style={{ display: 'block', objectFit: 'contain' }}
                    />
                    <label htmlFor="ios-button-image-file">
                      <input
                        name="hintIOSImage"
                        accept="*/*"
                        style={{ display: 'none' }}
                        id="ios-button-image-file"
                        type="file"
                        onBlur={handleBlur}
                        onChange={onIOSImageUpload}
                        ref={uploadIOSImageRef}
                      />
                      <Fab size="small" component="span" aria-label="add">
                        <FileUploadIcon />
                      </Fab>
                    </label>
                  </Grid>
                  {errors.hintIOSImage && !values.hintIOSImage && (
                    <p
                      style={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: 1.66,
                        letterSpacing: '0.03333em',
                        textAlign: 'left',
                        marginTop: '3px',
                        marginRight: '14px',
                        marginBottom: 0,
                        marginLeft: '14px',
                        color: '#d32f2f'
                      }}
                    >
                      {errors.hintIOSImage}
                    </p>
                  )}
                </Grid>

                <Grid item md={6} xs={6}>
                  <TextField
                    error={Boolean(touched.version && errors.version)}
                    fullWidth
                    helperText={touched.version && errors.version}
                    label="Version"
                    margin="normal"
                    name="version"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.version}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    id="select"
                    label="Catégorie"
                    value={values.categoryId || ''}
                    fullWidth
                    select
                    margin="normal"
                    name="categoryId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    error={Boolean(touched.categoryId && errors.categoryId)}
                    helperText={touched.categoryId && errors.categoryId}
                  >
                    <MenuItem value="" key="category_null" />
                    {categories.map((category) => (
                      <MenuItem value={category.id} key={`category_${category.id}`}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  {inputGlobalStyles}
                  <CKEditor
                    /* styles={{
                      '.ck.ck-balloon-panel': {
                        zIndex: '1400 !important', // Put a higher value that your MUI Dialog (generaly 1300)
                      },
                    }} */
                    editor={ClassicEditor}
                    name="description"
                    data={values.description || ''}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFieldValue('description', data);
                      validateForm();
                    }}
                  />
                  {errors.description && !values.description && (
                    <p
                      style={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: 1.66,
                        letterSpacing: '0.03333em',
                        textAlign: 'left',
                        marginTop: '3px',
                        marginRight: '14px',
                        marginBottom: 0,
                        marginLeft: '14px',
                        color: '#d32f2f'
                      }}
                    >
                      {errors.description}
                    </p>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button style={{ marginRight: '10px' }} color="secondary" variant="contained" onClick={onClose}>
                Annuler
              </Button>
              <Button color="primary" variant="contained" onClick={handleSubmit}>
                {hint ? 'Modifier' : 'Ajouter'}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

HintForm.propTypes = {
  hint: PropTypes.object,
  categories: PropTypes.array,
  onClose: PropTypes.func
};

export default HintForm;
