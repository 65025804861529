import { useContext } from 'react';
import AppContext from 'src/contexts/AppContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem
} from '@mui/material';
import { createLine, updateLine } from 'src/services/line.service';

const LineForm = ({ lineTypes, line, onClose }) => {
  // Get AppContext
  const contextProps = useContext(AppContext);

  const { setMessage } = contextProps;
  const getOrderValidator = () => {
    return Yup.number().min(1, "L'ordre doit être supérieur ou égal à 1").required("L'ordre est obligatoire");
  };
  return (
    <Formik
      initialValues={{
        code: line ? line.code : '',
        name: line ? line.name : '',
        color: line ? line.color : '',
        type: line ? line.type : '',
        order: line ? line.order : '',
        enabled: line ? line.enabled : true
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().required('Le code est obligatoire'),
        name: Yup.string().required('Le nom est obligatoire'),
        color: Yup.string()
          .matches('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$', 'La couleur est invalide')
          .required('La couleur est obligatoire'),
        type: Yup.string().required('Le type est obligatoire'),
        order: getOrderValidator(),
        enabled: Yup.boolean().default(true)
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        const { name, code, color, type, order, enabled } = values;
        const selectedType = lineTypes.find((lineType) => lineType.id === type);
        const max = selectedType.lines.length + 1;
        if (order > max) {
          setFieldError('order', `L'ordre doit être inférieur ou égal à ${max}`);
          return;
        }
        if (line) {
          const urls = [updateLine(line.id, { name, code, color, type, order, enabled })];
          Promise.all(urls)
            .then(() => {
              setSubmitting(false);
              onClose(true);
            })
            .catch((e) => {
              if (axios.isAxiosError(e)) {
                setMessage({ type: 'error', text: `Erreur lors de la mise à jour de la ligne : ${e.response.data.message}` });
              } else {
                setMessage({ type: 'error', text: 'Erreur lors de la mise à jour de la ligne' });
              }
            });
        } else {
          createLine({ name, color, type, order, enabled, code })
            .then(() => {
              setSubmitting(false);
              onClose(true);
            })
            .catch((e) => {
              if (axios.isAxiosError(e)) {
                setMessage({ type: 'error', text: `Erreur lors de la création de la ligne : ${e.response.data.message}` });
              } else {
                setMessage({ type: 'error', text: 'Erreur lors de la création de la ligne' });
              }
            });
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form
          autoComplete="off"
          noValidate
          style={{
            width: '500px'
          }}
        >
          <Card>
            <CardHeader subheader={line ? "Modification d'une ligne" : 'créer une nouvelle ligne'} title="Ligne" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.code && errors.code)}
                    fullWidth
                    helperText={touched.code && errors.code}
                    label="Code"
                    margin="normal"
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="string"
                    value={values.code}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nom"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column'
                    }}
                  >
                    <TextField
                      error={Boolean(touched.color && errors.color)}
                      fullWidth
                      helperText={touched.color && errors.color}
                      label="Couleur"
                      format="hex"
                      margin="normal"
                      name="color"
                      isAlphaHidden
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="color"
                      value={values.color}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    id="select"
                    label="Type de ligne"
                    value={values.type}
                    fullWidth
                    select
                    margin="normal"
                    name="type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    {lineTypes.map((lineType) => (
                      <MenuItem value={lineType.id} key={lineType.name}>
                        {lineType.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.order && errors.order)}
                    fullWidth
                    helperText={touched.order && errors.order}
                    label="Ordre"
                    margin="normal"
                    name="order"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.order}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.enabled}
                        value={values.enabled}
                        name="enabled"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Visible"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button style={{ marginRight: '10px' }} color="secondary" variant="contained" onClick={onClose}>
                Annuler
              </Button>
              <Button color="primary" variant="contained" onClick={handleSubmit}>
                {line ? 'Modifier' : 'Ajouter'}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

LineForm.propTypes = {
  lineTypes: PropTypes.array,
  line: PropTypes.object,
  onClose: PropTypes.func
};

export default LineForm;
