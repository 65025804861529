import { useContext, useRef, useState, useEffect } from 'react';
import AppContext from 'src/contexts/AppContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Fab
} from '@mui/material';
import {
  createInterstitial,
  updateInterstitial,
  getInterstitialAlerts,
  uploadInterstitialImage
} from 'src/services/interstitial.service';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { DateTime } from 'luxon';
import { DateTimePicker } from '@mui/x-date-pickers';
import placeholder from '../../assets/placeholder-image.png';

const InterstitialForm = ({ interstitial, onClose }) => {
  // Get AppContext
  const contextProps = useContext(AppContext);

  const [imageUrl, setImageUrl] = useState('');

  const uploadImageRef = useRef();
  const formik = useRef();

  const { setMessage } = contextProps;

  const [alerts, setAlerts] = useState([]);
  /**
   * On change on interstitial
   */
  useEffect(() => {
    getInterstitialAlerts().then((alertsResponse) => setAlerts(alertsResponse.data));

    if (interstitial) {
      setImageUrl(interstitial.imageUrl);
      formik.current.values.interstitialImage = interstitial.imageUrl;
    }
  }, [interstitial]);

  const onImageUpload = () => {
    uploadInterstitialImage(uploadImageRef.current.files[0]).then((uploadResponse) => {
      setImageUrl(uploadResponse.data.url);
      formik.current.values.interstitialImage = uploadResponse.data.url;
    });
  };

  return (
    <Formik
      innerRef={formik}
      initialValues={{
        title: interstitial ? interstitial.title : undefined,
        priority: interstitial ? interstitial.priority === 1 : false,
        startDate: interstitial ? DateTime.fromISO(interstitial.start) : undefined,
        endDate: interstitial ? DateTime.fromISO(interstitial.end) : undefined,
        time: interstitial ? interstitial.time : undefined,
        backgroundColor: interstitial ? interstitial.backgroundColor : '#FFFFFF',
        redirect: interstitial ? interstitial.redirect : 0,
        externalUrl: interstitial ? interstitial.externalUrl || '' : '',
        infoTrafic: interstitial ? interstitial.infoTrafic || '' : '',
        interstitialImage: undefined
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('Le titre est obligatoire'),
        startDate: Yup.date().required('La date de début est obligatoire'),
        endDate: Yup.date().required('La date de fin est obligatoire'),
        time: Yup.number().required("L'intervalle entre 2 apparitions est obligatoire"),
        backgroundColor: Yup.string()
          .matches('^#([a-fA-F0-9]{6})$', 'La couleur est invalide')
          .required('La couleur est obligatoire'),
        redirect: Yup.string().required('Le type est obligatoire'),
        interstitialImage: Yup.string().required("L'image est obligatoire"),
        priority: Yup.boolean().default(false)
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        const { title, startDate, endDate, backgroundColor, time, redirect, externalUrl, priority, infoTrafic } = values;
        let errorCount = 0;

        if (!(endDate > startDate)) {
          setFieldError('endDate', 'Les dates de début et de fin ne sont pas correctes');
          errorCount++;
        }

        if (redirect === '1' && externalUrl.length === 0) {
          setFieldError('externalUrl', "L'url externe est oblitgatoire");
          errorCount++;
        }

        if (redirect === '2' && infoTrafic.length === 0) {
          setFieldError('infoTrafic', 'La référence info traffic est oblitgatoire');
          errorCount++;
        }

        if (errorCount > 0) {
          return;
        }

        const priorityValue = priority ? 1 : 0;

        if (interstitial) {
          updateInterstitial(interstitial.id, {
            title,
            start: startDate,
            end: endDate,
            time: String(time),
            backgroundColor,
            redirect: String(redirect),
            externalUrl,
            infoTrafic,
            priority: priorityValue,
            imageUrl
          })
            .then(() => {
              setSubmitting(false);
              onClose(true);
            })
            .catch((e) => {
              if (axios.isAxiosError(e)) {
                setMessage({
                  type: 'error',
                  text: `Erreur lors de la mise à jour de l'interstitiel : ${e.response.data.message}`
                });
              } else {
                setMessage({ type: 'error', text: "Erreur lors de la mise à jour de l'interstitiel" });
              }
            });
        } else {
          createInterstitial({
            title,
            start: startDate,
            end: endDate,
            time: String(time),
            backgroundColor,
            redirect: String(redirect),
            externalUrl,
            infoTrafic,
            priority: priorityValue,
            imageUrl
          })
            .then(() => {
              setSubmitting(false);
              onClose(true);
            })
            .catch((e) => {
              if (axios.isAxiosError(e)) {
                setMessage({
                  type: 'error',
                  text: `Erreur lors de la création de de l'interstitiel : ${e.response.data.message}`
                });
              } else {
                setMessage({ type: 'error', text: "Erreur lors de la création de la l'interstitiel" });
              }
            });
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isValid, setFieldValue }) => {
        return (
          <form
            autoComplete="off"
            noValidate
            style={{
              width: '100%'
            }}
          >
            <Card sx={{ maxWidth: 780 }}>
              <CardHeader
                subheader={interstitial ? "Modification d'un interstitiel" : 'Créer un nouvel interstitiel'}
                title="Intertistiel"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      label="Titre"
                      margin="normal"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.title}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Grid
                      item
                      style={{
                        border: !values.interstitialImage && errors.interstitialImage ? '#d32f2f solid 1px' : '',
                        borderRadius: 5,
                        padding: 10
                      }}
                    >
                      <img
                        src={imageUrl || placeholder}
                        alt="interstitialImage"
                        width="300px"
                        height="150px"
                        style={{ display: 'block', objectFit: 'contain' }}
                      />
                      <label htmlFor="int-button-image-file">
                        <input
                          name="interstitialImage"
                          accept="*/*"
                          style={{ display: 'none' }}
                          id="int-button-image-file"
                          type="file"
                          onBlur={handleBlur}
                          onChange={onImageUpload}
                          ref={uploadImageRef}
                        />
                        <Fab size="small" component="span" aria-label="add">
                          <FileUploadIcon />
                        </Fab>
                      </label>
                    </Grid>
                    {errors.interstitialImage && !values.interstitialImage && (
                      <p
                        style={{
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontWeight: 400,
                          fontSize: '0.75rem',
                          lineHeight: 1.66,
                          letterSpacing: '0.03333em',
                          textAlign: 'left',
                          marginTop: '3px',
                          marginRight: '14px',
                          marginBottom: 0,
                          marginLeft: '14px',
                          color: '#d32f2f'
                        }}
                      >
                        {errors.interstitialImage}
                      </p>
                    )}
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Grid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.priority}
                            value={values.priority}
                            name="priority"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Prioritaire"
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'column'
                        }}
                      >
                        <TextField
                          error={Boolean(touched.backgroundColor && errors.backgroundColor)}
                          fullWidth
                          helperText={touched.backgroundColor && errors.backgroundColor}
                          label="Couleur"
                          format="hex"
                          margin="normal"
                          name="backgroundColor"
                          isAlphaHidden
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="color"
                          value={values.backgroundColor}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <DateTimePicker
                      error={Boolean(touched.startDate && errors.startDate)}
                      fullWidth
                      label="Date de début"
                      format="dd/MM/yyyy HH:mm"
                      margin="normal"
                      name="startDate"
                      onBlur={handleBlur}
                      onChange={(ev) => {
                        if (ev !== null) {
                          setFieldValue('startDate', ev);
                        }
                      }}
                      type="text"
                      value={values.startDate}
                      timezone="system"
                      variant="outlined"
                    />
                    {touched.startDate && errors.startDate && (
                      <p
                        style={{
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontWeight: 400,
                          fontSize: '0.75rem',
                          lineHeight: 1.66,
                          letterSpacing: '0.03333em',
                          textAlign: 'left',
                          marginTop: '3px',
                          marginRight: '14px',
                          marginBottom: 0,
                          marginLeft: '14px',
                          color: '#d32f2f'
                        }}
                      >
                        {errors.startDate}
                      </p>
                    )}
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <DateTimePicker
                      error={Boolean(touched.endDate && errors.endDate)}
                      fullWidth
                      helperText={touched.endDate && errors.endDate}
                      label="Date de fin"
                      format="dd/MM/yyyy HH:mm"
                      margin="normal"
                      name="endDate"
                      onBlur={handleBlur}
                      onChange={(ev) => {
                        if (ev !== null) {
                          setFieldValue('endDate', ev);
                        }
                      }}
                      type="text"
                      value={values.endDate}
                      timezone="system"
                      variant="outlined"
                    />
                    {touched.endDate && errors.endDate && (
                      <p
                        style={{
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontWeight: 400,
                          fontSize: '0.75rem',
                          lineHeight: 1.66,
                          letterSpacing: '0.03333em',
                          textAlign: 'left',
                          marginTop: '3px',
                          marginRight: '14px',
                          marginBottom: 0,
                          marginLeft: '14px',
                          color: '#d32f2f'
                        }}
                      >
                        {errors.endDate}
                      </p>
                    )}
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <TextField
                      error={Boolean(touched.time && errors.time)}
                      fullWidth
                      helperText={touched.time && errors.time}
                      label="Intervalle entre 2 apparitions"
                      margin="normal"
                      name="time"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.time}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={6} xs={6} />

                  <Grid item md={3} xs={3}>
                    <TextField
                      error={Boolean(touched.redirect && errors.redirect)}
                      helperText={touched.redirect && errors.redirect}
                      id="select"
                      label="Type de redirection"
                      value={values.redirect}
                      fullWidth
                      select
                      margin="normal"
                      name="redirect"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    >
                      <MenuItem value="0">None</MenuItem>
                      <MenuItem value="1">URL externe</MenuItem>
                      <MenuItem value="2">Info trafic</MenuItem>
                    </TextField>
                  </Grid>
                  {
                    values.redirect === '1' ? 
                      <Grid item md={9} xs={9}>
                        <TextField
                          error={Boolean(touched.externalUrl && errors.externalUrl)}
                          fullWidth
                          helperText={touched.externalUrl && errors.externalUrl}
                          label="External URL"
                          margin="normal"
                          name="externalUrl"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.externalUrl}
                          variant="outlined"
                        />
                      </Grid>
                      : null
                  }
                  {
                    values.redirect === '2' ?
                      <Grid item md={9} xs={9}>
                        <TextField
                          error={Boolean(touched.infoTrafic && errors.infoTrafic)}
                          fullWidth
                          helperText={touched.infoTrafic && errors.infoTrafic}
                          id="select"
                          label="Info Traffic"
                          value={alerts.length > 0 ? values.infoTrafic : ''} // Check if alerts already loaded
                          select
                          margin="normal"
                          name="infoTrafic"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        >
                          <MenuItem key="alert-null" value="" id="alert-null" />
                          {alerts.map((alert) => {
                            return (
                              <MenuItem key={alert.id} value={alert.id} id={alert.id}>
                                {alert.label}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      : null
                  }
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button style={{ marginRight: '10px' }} color="secondary" variant="contained" onClick={onClose}>
                  Annuler
                </Button>
                <Button color="primary" variant="contained" disabled={!isValid} onClick={handleSubmit}>
                  {interstitial ? 'Modifier' : 'Ajouter'}
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

InterstitialForm.propTypes = {
  interstitial: PropTypes.object,
  onClose: PropTypes.func
};

export default InterstitialForm;
