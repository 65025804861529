import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@mui/material';

const ConfirmDelete = ({ name, onClose }) => (
  <Card
    style={{
      width: '400px'
    }}
  >
    <CardHeader subheader="Suppression" />
    <Divider />
    <CardContent>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Typography align="left" color="textPrimary" variant="subtitle2">
            Vous voulez vraiment supprimer &quot;
            {name}
            &quot;?
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button onClick={() => onClose(false)}>Non</Button>
      <Button onClick={() => onClose(true)} color="primary" variant="contained">
        oui, Supprimer
      </Button>
    </Box>
  </Card>
);

ConfirmDelete.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func
};

export default ConfirmDelete;
