import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, CircularProgress } from '@mui/material';
import { DateTime } from 'luxon';

const ActionListBody = (props) => {
  const { data, onPaginate, count, isRequesting, limit, setLimit, page, setPage } = props;

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    onPaginate(page + 1, event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onPaginate(newPage + 1, limit);
  };

  return (
    <>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Utilisateur</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isRequesting ? (
                <TableRow hover>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                data.map((action) => (
                  <TableRow hover key={action.id}>
                    <TableCell>{action.username}</TableCell>
                    <TableCell>{DateTime.fromISO(action.date).toFormat('dd/MM/yyyy HH:mm')}</TableCell>
                    <TableCell>{action.message}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count * limit}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        showFirstButton
        showLastButton
      />
    </>
  );
};

ActionListBody.propTypes = {
  data: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onPaginate: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
};

export default ActionListBody;
