import { Delete, Get, Post, Put } from './network.service';

export const getLines = () => {
  return Get('lines');
};

export const createLine = (body) => {
  return Post('lines', body);
};

export const updateLine = (lineId, body) => {
  return Put(`lines/${lineId}`, body);
};

export const deleteLine = (lineId) => {
  return Delete(`lines/${lineId}`);
};

export const reorderLine = (lineId, order) => {
  return Put(`lines/${lineId}/order/${order}`, {});
};
