import { Delete, Get, Post, Put, Upload } from './network.service';

export const getHints = () => {
  return Get('hints');
};

export const createHint = (body) => {
  return Post('hints', body);
};

export const updateHint = (hintId, body) => {
  return Put(`hints/${hintId}`, body);
};

export const updatePublishHint = (hintId, value) => {
  return Put(`hints/${hintId}/publish/${value}`);
};

export const deleteHint = (hintId) => {
  return Delete(`hints/${hintId}`);
};

export const uploadHintImage = (file) => {
  return Upload('hints/images', 'image', file);
};

export const reorderHint = (hintId, order) => {
  return Put(`hints/${hintId}/order/${order}`, {});
};
