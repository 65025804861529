import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Settings } from 'luxon';
import * as serviceWorker from './serviceWorker';
import App from './App';

Settings.defaultLocale = 'fr';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>
);
serviceWorker.unregister();
