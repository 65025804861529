import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import FluxForm from '../components/flux/FluxForm';
import { isAutenticated } from '../services/login.service';

const FluxPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAutenticated()) {
      navigate('/login');
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>RTM | Flux</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 11
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={8} md={12} xs={12}>
              <FluxForm />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default FluxPage;
