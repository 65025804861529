import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, Dialog } from '@mui/material';
import UsersList from '../components/users/UsersList';
import UserForm from '../components/users/UserForm';
import ConfirmDelete from '../components/users/ConfirmDelete';
import { isAutenticated } from '../services/login.service';
import { deleteUser, getUsers } from '../services/user.service';

const UsersPage = () => {
  const navigate = useNavigate();
  const [users, setusers] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
  const [SelectedUser, setSelectedUser] = useState();
  const [isRequesting, setisRequeting] = useState(false);
  const [modalStyle] = useState({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

  const loadUsers = () => {
    setisRequeting(true);
    getUsers().then((res) => {
      const { data } = res;
      setusers([...data]);
      setisRequeting(false);
    });
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadUsers();
    } else {
      navigate('/login');
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const openCreateUser = () => {
    setSelectedUser(null);
    setOpen(true);
  };

  const closeDialog = () => {
    setSelectedUser(null);
    setOpen(false);
    setopenConfirmationDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>RTM - Utilisateurs</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          pt: 7
        }}
      >
        <Container maxWidth={false}>
          <div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button color="primary" variant="contained" onClick={openCreateUser}>
                Ajouter Utilisateur
              </Button>
            </Box>
          </div>

          <Box sx={{ pt: 3 }}>
            <UsersList
              data={users}
              isRequesting={isRequesting}
              onEdit={(userToEdit) => {
                setSelectedUser(userToEdit);
                setOpen(true);
              }}
              onDelete={(userToDelete) => {
                setSelectedUser(userToDelete);
                setOpen(true);
                setopenConfirmationDialog(true);
              }}
            />
            <Dialog open={open} onClose={handleClose} style={modalStyle}>
              {openConfirmationDialog ? (
                <ConfirmDelete
                  onClose={(isDelete) => {
                    if (isDelete) {
                      deleteUser(SelectedUser.id).then(() => {
                        closeDialog();
                        loadUsers();
                      });
                    } else {
                      closeDialog();
                    }
                  }}
                />
              ) : (
                <UserForm
                  user={SelectedUser}
                  onClose={(isRefresh) => {
                    closeDialog();
                    if (isRefresh) {
                      loadUsers();
                    }
                  }}
                />
              )}
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default UsersPage;
