import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { DateTime } from 'luxon';
import { RestoreOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';

const BDDRecoveryForm = ({ onGoing, handleRestore, backups }) => {
  return (
    <Card>
      <CardHeader subheader="Restauration de la base de données mobiles" title="Init BDD" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="tablecell-green">Libellé</TableCell>
                  <TableCell className="tablecell-green">Date et heure</TableCell>
                  <TableCell className="tablecell-green" style={{ width: '50px' }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {backups.map(({ label, time }) => (
                  <TableRow key={time} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{label}</TableCell>
                    <TableCell>{DateTime.fromISO(time).toFormat('HH:mm:ss dd/MM/yyyy')}</TableCell>
                    <TableCell>
                      <Tooltip title="Restaurer cette version">
                        <Button
                          startIcon={<RestoreOutlined />}
                          color="primary"
                          onClick={() => handleRestore(label)}
                          disabled={onGoing}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

BDDRecoveryForm.propTypes = {
  onGoing: PropTypes.bool,
  handleRestore: PropTypes.func,
  backups: PropTypes.array
};

export default BDDRecoveryForm;
