import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import UsersPage from './pages/Users';
import LinesPage from './pages/Lines';
import FluxPage from './pages/Flux';
import InterstitialsPage from './pages/Interstitials';
import HintsPage from './pages/Hints';
import ImportHistoryPage from './pages/ImportHistory';
import BDDPage from './pages/BDD';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'users', element: <UsersPage /> },
      { path: 'importHistory', element: <ImportHistoryPage /> },
      { path: 'lines', element: <LinesPage /> },
      { path: 'interstitials', element: <InterstitialsPage /> },
      { path: 'hints', element: <HintsPage /> },
      { path: 'flux', element: <FluxPage /> },
      { path: 'initBdd', element: <BDDPage /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
