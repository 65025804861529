import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Button, Tooltip } from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CompressIcon from '@mui/icons-material/Compress';
import EditIcon from '@mui/icons-material/Edit';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

const HintsList = ({ data, isRequesting, onHintEdit, onHintDelete, onHintPublishUpdate, onReorderHint }) => {
  const [hints, setHints] = useState(data);

  useEffect(() => {
    setHints(data);
  }, [data]);

  const handleDragEnd = (e) => {
    if (!e.destination) return;

    onReorderHint(e.draggableId, e.destination.index);
  };

  return (
    <PerfectScrollbar>
      <Box sx={{ minWidth: 800 }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tablecell-green">#</TableCell>
                <TableCell className="tablecell-green">Numéro de version</TableCell>
                <TableCell className="tablecell-green">Rubrique</TableCell>
                <TableCell className="tablecell-green">Titre</TableCell>
                <TableCell className="tablecell-green">#</TableCell>
                <TableCell className="tablecell-green" style={{ width: '250px' }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {!isRequesting ? (
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                    {hints.map(
                      (
                        {
                          id,
                          title,
                          version,
                          categoryId,
                          categoryLabel,
                          description,
                          androidImageUrl,
                          iosImageUrl,
                          published,
                          order
                        },
                        index
                      ) => (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provider2) => (
                            <TableRow
                              key={id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              {...provider2.draggableProps}
                              ref={provider2.innerRef}
                            >
                              <TableCell {...provider2.dragHandleProps}>
                                <CompressIcon />
                              </TableCell>
                              <TableCell>{version}</TableCell>
                              <TableCell>{categoryLabel}</TableCell>
                              <TableCell>{title}</TableCell>
                              <TableCell>{order}</TableCell>
                              <TableCell>
                                <Tooltip title="Editer l'astuce">
                                  <Button
                                    startIcon={<EditIcon />}
                                    href="#text-buttons"
                                    color="primary"
                                    onClick={() =>
                                      onHintEdit({
                                        id,
                                        title,
                                        categoryId,
                                        categoryLabel,
                                        version,
                                        description,
                                        androidImageUrl,
                                        iosImageUrl,
                                        published
                                      })
                                    }
                                  />
                                </Tooltip>
                                <Tooltip title="Supprimer l'astuce">
                                  <Button
                                    startIcon={<DeleteOutlineIcon />}
                                    color="secondary"
                                    onClick={() => onHintDelete({ id, title })}
                                  />
                                </Tooltip>
                                <Tooltip title={published === 0 ? "Publier l'astuce" : "Dépublier l'astuce"}>
                                  <Button
                                    startIcon={published === 1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    color="primary"
                                    onClick={() => onHintPublishUpdate({ id, title, published })}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      )
                    )}
                    {provider.placeholder}
                  </TableBody>
                )}
              </Droppable>
            ) : (
              <TableBody>
                <TableRow hover>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </DragDropContext>
      </Box>
    </PerfectScrollbar>
  );
};

HintsList.propTypes = {
  data: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool,
  onHintDelete: PropTypes.func,
  onHintEdit: PropTypes.func,
  onHintPublishUpdate: PropTypes.func,
  onReorderHint: PropTypes.func
};

export default HintsList;
