import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { getToken, isAutenticated } from '../../services/login.service';

const UsersList = ({ data, isRequesting, onEdit, onDelete }) => {
  const [userId, setuserId] = useState();
  useEffect(() => {
    if (isAutenticated()) {
      const { id } = jwtDecode(getToken());
      setuserId(id);
    }
  }, []);
  return (
    <PerfectScrollbar>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="tablecell-green">Username</TableCell>
              <TableCell className="tablecell-green">Email</TableCell>
              <TableCell className="tablecell-green" style={{ width: '250px' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {!isRequesting ? (
            <TableBody>
              {data.map(({ id, username, email }) => (
                <TableRow hover key={id}>
                  <TableCell>{username}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>
                    <Button
                      startIcon={<EditIcon />}
                      href="#text-buttons"
                      color="primary"
                      onClick={() => onEdit({ id, username, email })}
                    >
                      Éditer
                    </Button>
                    {userId !== id && (
                      <Button
                        startIcon={<DeleteOutlineIcon />}
                        color="secondary"
                        onClick={() => onDelete({ id, username, email })}
                      >
                        Supprimer
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow hover>
                <TableCell colSpan={11} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

UsersList.propTypes = {
  data: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default UsersList;
