import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import LineTypeTableRow from './LineTypeTableRow';

const LineTypesList = ({ data, isRequesting, onLineTypeEdit, onLineTypeDelete, onLineEdit, onLineDelete, onReorderLine }) => {
  return (
    <PerfectScrollbar>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="tablecell-green">Ordre</TableCell>
              <TableCell className="tablecell-green">Code</TableCell>
              <TableCell className="tablecell-green">Nom</TableCell>
              <TableCell className="tablecell-green">Visible</TableCell>
              <TableCell className="tablecell-green" style={{ width: '250px' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {!isRequesting ? (
            <TableBody>
              {data.map((lineType, index) => {
                return (
                  <LineTypeTableRow
                    key={lineType.id}
                    index={index + 1}
                    lineType={lineType}
                    onLineTypeEdit={onLineTypeEdit}
                    onLineTypeDelete={onLineTypeDelete}
                    onLineEdit={onLineEdit}
                    onLineDelete={onLineDelete}
                    onReorderLine={onReorderLine}
                  />
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow hover>
                <TableCell colSpan={11} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

LineTypesList.propTypes = {
  data: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool,
  onLineTypeDelete: PropTypes.func,
  onLineTypeEdit: PropTypes.func,
  onLineEdit: PropTypes.func,
  onLineDelete: PropTypes.func,
  onReorderLine: PropTypes.func
};

export default LineTypesList;
