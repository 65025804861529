import logo from '../assets/logo.svg';

const Logo = (props) => (
  <img
    alt="Logo"
    src={logo}
    {...props}
  />
);

export default Logo;
