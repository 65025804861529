import { Get, Post, Put } from './network.service';

export const generateInitBDDDatabase = (body) => {
  return Post('init-bdd/generate', body);
};

export const transferInitBDDDatabase = () => {
  return Post('init-bdd/transfer', {});
};

export const listBackupInitBDDDatabase = () => {
  return Get('init-bdd/backups');
};

export const restoreBackupInitBDDDatabase = (backupId) => {
  return Put(`init-bdd/backups/${backupId}`);
};

export const getInitBDDStatus = () => {
  return Get('init-bdd/status');
};

export const getInitBDDConfig = () => {
  return Get('init-bdd/config');
};
