import { Delete, Get, Post, Put, Upload } from './network.service';

export const getInterstitials = () => {
  return Get('interstitials');
};

export const createInterstitial = (body) => {
  return Post('interstitials', body);
};

export const updateInterstitial = (interstitialId, body) => {
  return Put(`interstitials/${interstitialId}`, body);
};

export const updatePublishInterstitial = (interstitialId, value) => {
  return Put(`interstitials/${interstitialId}/publish/${value}`);
};

export const deleteInterstitial = (interstitialId) => {
  return Delete(`interstitials/${interstitialId}`);
};

export const getInterstitialAlerts = () => {
  return Get('interstitials/alerts');
};

export const uploadInterstitialImage = (file) => {
  return Upload('interstitials/images', 'image', file);
};
