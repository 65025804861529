import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const envSources = [
  {
    value: '',
    label: ''
  },
  {
    value: 'PREPROD',
    label: 'Pré-production'
  },
  {
    value: 'PROD',
    label: 'Production'
  }
];

const envTargets = [
  {
    value: '',
    label: ''
  }
];
let envLoaded = false;

const BDDGenerationForm = ({ onGoing, handleGenerate }) => {
  /**
   * Get init bdd status
   */
  if (!envLoaded) {
    envLoaded = true;
    if (window.SHOW_DEV) {
      envTargets.push({
        value: 'DEV',
        label: 'Développement'
      });
    }
    envTargets.push({
      value: 'PREPROD',
      label: 'Pré-production'
    });
    envTargets.push({
      value: 'PROD',
      label: 'Production'
    });
  }

  return (
    <Formik
      initialValues={{
        envSource: ''
      }}
      validationSchema={Yup.object().shape({
        envSource: Yup.string().required('La source est obligatoire')
      })}
      onSubmit={(values) => {
        handleGenerate(values);
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form autoComplete="off">
          <Card>
            <CardHeader subheader="Génération des bases de données mobiles" title="Init BDD" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(touched.envSource && errors.envSource)}
                    helperText={touched.envSource && errors.envSource}
                    fullWidth
                    label="Source"
                    name="envSource"
                    required
                    select
                    SelectProps={{ native: true }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.envSource}
                    variant="outlined"
                  >
                    {envSources.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>

            <Divider />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button color="primary" variant="contained" onClick={handleSubmit} disabled={onGoing}>
                Générer
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

BDDGenerationForm.propTypes = {
  onGoing: PropTypes.bool,
  handleGenerate: PropTypes.func
};

export default BDDGenerationForm;
