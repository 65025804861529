import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, Dialog } from '@mui/material';
import { reorderLine } from 'src/services/line.service';
import LineTypesList from '../components/lines/LineTypesList';
import LineForm from '../components/lines/LineForm';
import LineTypeForm from '../components/lines/LineTypeForm';
import ConfirmDelete from '../components/lines/ConfirmDelete';
import { isAutenticated } from '../services/login.service';
import { deleteLine } from '../services/line.service';
import { deleteLineType, getLineTypes } from '../services/line-type.service';

const LinesPage = () => {
  const navigate = useNavigate();
  const [lineTypes, setlineTypes] = useState([]);
  const [openLine, setOpenLine] = useState(false);
  const [openLineType, setOpenLineType] = useState(false);
  const [openLineConfirmationDialog, setOpenLineConfirmationDialog] = useState(false);
  const [openLineTypeConfirmationDialog, setOpenLineTypeConfirmationDialog] = useState(false);
  const [selectedLine, setSelectedLine] = useState();
  const [selectedLineType, setSelectedLineType] = useState();
  const [isRequesting, setisRequeting] = useState(false);
  const [modalStyle] = useState({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

  const loadLineTypes = () => {
    setisRequeting(true);
    getLineTypes().then((res) => {
      const { data } = res;
      setlineTypes([...data]);
      setisRequeting(false);
    });
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadLineTypes();
    } else {
      navigate('/login');
    }
  }, []);

  const handleClose = () => {
    setOpenLine(false);
    setOpenLineType(false);
  };

  const openCreateLine = () => {
    setSelectedLine(null);
    setOpenLine(true);
  };

  const openCreateLineType = () => {
    setSelectedLineType(null);
    setOpenLineType(true);
  };

  const closeDialog = () => {
    setSelectedLine(null);
    setSelectedLineType(null);
    setOpenLine(false);
    setOpenLineType(false);
    setOpenLineConfirmationDialog(false);
    setOpenLineTypeConfirmationDialog(false);
  };

  const onReorderLine = (lineId, order) => {
    reorderLine(lineId, order).then(() => {
      loadLineTypes();
    });
  };

  return (
    <>
      <Helmet>
        <title>RTM - Utilisateurs</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button sx={{ margin: '10px' }} color="secondary" variant="contained" onClick={openCreateLineType}>
                Ajouter un type de ligne
              </Button>
              <Button sx={{ margin: '10px' }} color="primary" variant="contained" onClick={openCreateLine}>
                Ajouter une ligne
              </Button>
            </Box>
          </Box>

          <Box sx={{ pt: 3 }}>
            <LineTypesList
              data={lineTypes}
              isRequesting={isRequesting}
              onLineTypeEdit={(lineTypeToEdit) => {
                setSelectedLineType(lineTypeToEdit);
                setOpenLineType(true);
              }}
              onLineTypeDelete={(lineTypeToDelete) => {
                setSelectedLineType(lineTypeToDelete);
                setOpenLineType(true);
                setOpenLineTypeConfirmationDialog(true);
              }}
              onLineEdit={(lineToEdit) => {
                setSelectedLine(lineToEdit);
                setOpenLine(true);
              }}
              onLineDelete={(lineToDelete) => {
                setSelectedLine(lineToDelete);
                setOpenLine(true);
                setOpenLineConfirmationDialog(true);
              }}
              onReorderLine={onReorderLine}
            />
            <Dialog open={openLine} onClose={handleClose} style={modalStyle}>
              {openLineConfirmationDialog ? (
                <ConfirmDelete
                  name={selectedLine.name}
                  onClose={(isDelete) => {
                    if (isDelete) {
                      deleteLine(selectedLine.id).then(() => {
                        closeDialog();
                        loadLineTypes();
                      });
                    } else {
                      closeDialog();
                    }
                  }}
                />
              ) : (
                <LineForm
                  lineTypes={lineTypes}
                  line={selectedLine}
                  onClose={(isRefresh) => {
                    closeDialog();
                    if (isRefresh) {
                      loadLineTypes();
                    }
                  }}
                />
              )}
            </Dialog>
            <Dialog open={openLineType} onClose={handleClose} style={modalStyle}>
              {openLineTypeConfirmationDialog ? (
                <ConfirmDelete
                  name={selectedLineType.name}
                  onClose={(isDelete) => {
                    if (isDelete) {
                      deleteLineType(selectedLineType.id).then(() => {
                        closeDialog();
                        loadLineTypes();
                      });
                    } else {
                      closeDialog();
                    }
                  }}
                />
              ) : (
                <LineTypeForm
                  lineTypes={lineTypes}
                  lineType={selectedLineType}
                  onClose={(isRefresh) => {
                    closeDialog();
                    if (isRefresh) {
                      loadLineTypes();
                    }
                  }}
                />
              )}
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default LinesPage;
