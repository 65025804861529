import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, Dialog } from '@mui/material';
import ConfirmPublish from 'src/components/lines/ConfirmPublish';
import InterstitialsList from '../components/intertistials/InterstitialsList';
import InterstitialForm from '../components/intertistials/InterstitialForm';
import ConfirmDelete from '../components/lines/ConfirmDelete';
import { isAutenticated } from '../services/login.service';
import { getInterstitials, deleteInterstitial, updatePublishInterstitial } from '../services/interstitial.service';

const InterstitialsPage = () => {
  const navigate = useNavigate();
  const [interstitials, setInterstitials] = useState([]);
  const [selectedInterstitial, setSelectedInterstitial] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const [openConfirmationDeleteDialog, setOpenConfirmationDeleteDialog] = useState(false);
  const [openConfirmationPublishDialog, setOpenConfirmationPublishDialog] = useState(false);

  const [isRequesting, setisRequeting] = useState(false);
  const [modalStyle] = useState({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

  const loadInterstitials = () => {
    setOpenConfirmationDeleteDialog(false);
    setOpenConfirmationPublishDialog(false);
    setisRequeting(true);

    getInterstitials().then((res) => {
      const { data } = res;
      setInterstitials([...data]);
      setisRequeting(false);
    });
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadInterstitials();
    } else {
      navigate('/login');
    }
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
    setOpenConfirmationDeleteDialog(false);
    setOpenConfirmationPublishDialog(false);
  };

  const openCreateInterstitial = () => {
    setSelectedInterstitial(null);
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setOpenConfirmationDeleteDialog(false);
    setOpenConfirmationPublishDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>RTM - Interstitiels</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button sx={{ margin: '10px' }} color="primary" variant="contained" onClick={openCreateInterstitial}>
                Ajouter un interstitiel
              </Button>
            </Box>
          </Box>

          <Box sx={{ pt: 3 }}>
            <InterstitialsList
              data={interstitials}
              isRequesting={isRequesting}
              onInterstitialEdit={(interstitialToEdit) => {
                setSelectedInterstitial(interstitialToEdit);
                setOpenDialog(true);
              }}
              onInterstitialDelete={(interstitialToDelete) => {
                setSelectedInterstitial(interstitialToDelete);
                setOpenConfirmationDeleteDialog(true);
                setOpenDialog(true);
              }}
              onInterstitialPublishUpdate={(interstitialToPublishUpdate) => {
                setSelectedInterstitial(interstitialToPublishUpdate);
                setOpenConfirmationPublishDialog(true);
                setOpenDialog(true);
              }}
            />
            <Dialog open={openDialog} onClose={handleClose} style={modalStyle} maxWidth={false}>
              {openConfirmationPublishDialog && (
                <ConfirmPublish
                  name={selectedInterstitial.title}
                  onClose={(changePublish) => {
                    if (changePublish) {
                      updatePublishInterstitial(selectedInterstitial.id, selectedInterstitial.published === 1 ? 0 : 1).then(
                        () => {
                          closeDialog();
                          loadInterstitials();
                        }
                      );
                    } else {
                      closeDialog();
                    }
                  }}
                />
              )}
              {openConfirmationDeleteDialog && (
                <ConfirmDelete
                  name={selectedInterstitial.title}
                  onClose={(isDelete) => {
                    if (isDelete) {
                      deleteInterstitial(selectedInterstitial.id).then(() => {
                        closeDialog();
                        loadInterstitials();
                      });
                    } else {
                      closeDialog();
                    }
                  }}
                />
              )}
              {!openConfirmationDeleteDialog && !openConfirmationPublishDialog && (
                <InterstitialForm
                  interstitial={selectedInterstitial}
                  onClose={(isRefresh) => {
                    closeDialog();
                    if (isRefresh) {
                      loadInterstitials();
                    }
                  }}
                />
              )}
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default InterstitialsPage;
