import { Delete, Get, Post, Put } from './network.service';

export const getLineTypes = () => {
  return Get('line-types');
};

export const createLineType = (body) => {
  return Post('line-types', body);
};

export const updateLineType = (lineTypeId, body) => {
  return Put(`line-types/${lineTypeId}`, body);
};

export const deleteLineType = (lineTypeId) => {
  return Delete(`line-types/${lineTypeId}`);
};
