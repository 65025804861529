import axios from 'axios';

export const Get = (url, options = {}) => {
  return axios.get(window.API_URL + url, options);
};

export const Post = (url, data, options = {}) => {
  return axios.post(window.API_URL + url, data, options);
};

export const Delete = (url, options = {}) => {
  return axios.delete(window.API_URL + url, options);
};

export const Put = (url, data, options = {}) => {
  return axios.put(window.API_URL + url, data, options);
};

export const Patch = (url, data, options = {}) => {
  return axios.patch(window.API_URL + url, data, options);
};

export const Upload = (url, filename, data) => {
  const formData = new FormData();
  formData.append(filename, data);

  return axios.post(window.API_URL + url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

axios.interceptors.request.use(
  (request) => {
    const config = request;
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    const code = response.status;
    const isNotLogin = !response.config.url.includes('/login');
    if (code === 401 && isNotLogin) {
      localStorage.clear();
      window.location.replace('/login');
    }

    return Promise.reject(error);
  }
);
