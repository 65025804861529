import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { createLineType, updateLineType } from 'src/services/line-type.service';

const LineTypeForm = ({ lineTypes, lineType, onClose }) => {
  const getOrderValidator = () => {
    const max = lineTypes.length + 1;
    return Yup.number()
      .min(1, "L'ordre doit être supérieur ou égal à 1")
      .max(max, `L'ordre doit être inférieur ou égal à ${max}`)
      .required("L'ordre est obligatoire");
  };
  return (
    <Formik
      initialValues={{
        code: lineType ? lineType.code : '',
        name: lineType ? lineType.name : '',
        order: lineType ? lineType.order : '',
        enabled: lineType ? lineType.enabled : true,
        isSubsidiary: lineType ? lineType.isSubsidiary : false
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().required('le code est obligatoire'),
        name: Yup.string().required('Le nom est obligatoire'),
        order: getOrderValidator(),
        enabled: Yup.boolean().default(true),
        isSubsidiary: Yup.boolean().default(false)
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { code, name, order, enabled, isSubsidiary } = values;
        if (lineType) {
          const urls = [updateLineType(lineType.id, { code, name, order, enabled, isSubsidiary })];
          Promise.all(urls).then(() => {
            setSubmitting(false);
            onClose(true);
          });
        } else {
          createLineType({ code, name, order, enabled, isSubsidiary }).then(() => {
            setSubmitting(false);
            onClose(true);
          });
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form
          autoComplete="off"
          noValidate
          style={{
            width: '500px'
          }}
        >
          <Card>
            <CardHeader subheader={lineType ? "Modification d'un type de ligne" : 'Créer un type ligne'} title="Type de ligne" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.code && errors.code)}
                    fullWidth
                    helperText={touched.code && errors.code}
                    label="Code"
                    margin="normal"
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.code}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nom"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.order && errors.order)}
                    fullWidth
                    helperText={touched.order && errors.order}
                    label="Ordre"
                    margin="normal"
                    name="order"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.order}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.enabled}
                        value={values.enabled}
                        name="enabled"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Visible"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isSubsidiary}
                        value={values.isSubsidiary}
                        name="isSubsidiary"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Ligne filiale"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button style={{ marginRight: '10px' }} color="secondary" variant="contained" onClick={onClose}>
                Annuler
              </Button>
              <Button color="primary" variant="contained" onClick={handleSubmit}>
                {lineType ? 'Modifier' : 'Ajouter'}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

LineTypeForm.propTypes = {
  lineTypes: PropTypes.array,
  lineType: PropTypes.object,
  onClose: PropTypes.func
};

export default LineTypeForm;
