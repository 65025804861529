import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';

const MessageSuccess = ({ onClose }) => (
  <Card
    style={{
      width: '400px'
    }}
  >
    <CardContent>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Typography align="left" color="textPrimary" variant="subtitle1">
            La configuration a été enregistrée
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button onClick={() => onClose(true)} color="primary" variant="contained">
        Fermer
      </Button>
    </Box>
  </Card>
);

MessageSuccess.propTypes = {
  onClose: PropTypes.func
};

export default MessageSuccess;
