import React, { useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import AppContext from '../../contexts/AppContext';
import { resyncAllMain, resyncSubsidiariesByIds } from '../../services/middleware.service';

const ImportsList = ({ data, subsidiaries, isRequesting }) => {
  const contextProps = useContext(AppContext);
  const { setMessage } = contextProps;

  const getRightColorLabel = (label) => {
    switch (label.toLowerCase()) {
      case 'ok':
        return <p style={{ fontWeight: 'bold', color: 'green' }}>{label}</p>;
      case 'error':
        return <p style={{ fontWeight: 'bold', color: 'red' }}>{label}</p>;
      default:
        return <p style={{ fontWeight: 'bold', color: 'orange' }}>{label}</p>;
    }
  };

  const triggerImportDataSpoti3 = () => {
    resyncAllMain()
      .then(() => {
        setMessage({ type: 'success', text: 'Import manuel pour les lignes principales lancé avec succès' });
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch((error) => {
        if (error.response.status === HttpStatusCode.NotAcceptable) {
          setMessage({ type: 'error', text: "Erreur lors de l'import manuel, un autre import est toujours en cours" });
        }
      });
  };

  /**
   *
   * @param {string[]} agencies
   */
  const triggerImportDataSubsidiaries = (agencies) => {
    const allGtfsIds = agencies || data.gtfs.map((item) => item.agencyId);

    if (allGtfsIds.length === 0) {
      triggerImportDataSubsidiaries(subsidiaries.map((item) => item.agencyId));
    } else {
      resyncSubsidiariesByIds(allGtfsIds)
        .then(() => {
          setMessage({ type: 'success', text: 'Import manuel GTFS lancé avec succès' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {
          if (error.response.status === HttpStatusCode.NotAcceptable) {
            setMessage({ type: 'error', text: "Erreur lors de l'import manuel, un autre import est toujours en cours" });
          }
        });
    }
  };

  const reImport = (agencyId) => {
    triggerImportDataSubsidiaries([agencyId]);
  };

  /**
   * @returns boolean
   */
  const isImportInProgress = () => {
    return !!data.gtfs.filter((item) => item.importInProgress).length;
  };

  const mapSubsidiariesGtfsData = () => {
    // Sorting
    const importOrder = subsidiaries.map((item) => item.agencyId);

    const sortByObject = importOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index
      };
    }, {});

    const customSort = data.gtfs.sort((a, b) => sortByObject[a.agencyId] - sortByObject[b.agencyId]);

    /**
     * @type [{
     * active: boolean
     * agencyId: string
     * dateImport: Date
     * gtfsDownloadStatus: string
     * gtfsImportStatus: string
     * id: string
     * importInProgress: boolean
     * lineStatus: string
     * stationLineStatus: string
     * stationStatus: string
     * timetableStatus: string
     * }]
     */
    const subsidiariesGtfsData = customSort;
    const groupeElements = _.groupBy(subsidiariesGtfsData, (o) => o.agencyId);
    return groupeElements;
  };

  const getAgencyName = (key) => {
    return subsidiaries.find((item) => item.agencyId === key).agencyName;
  };

  return (
    <Box>
      <Box sx={{ pt: 3, pb: 3 }}>
        <Button onClick={triggerImportDataSpoti3} color="primary" variant="contained">
          Re-Importer les données SPOTI3
        </Button>
      </Box>
      <Card>
        <CardHeader title="Status des imports SPOTI3" />
        <Divider />
        <PerfectScrollbar>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="tablecell-green">Date</TableCell>
                  <TableCell className="tablecell-green">Téléchargement DB</TableCell>
                  <TableCell className="tablecell-green">Lignes depuis DB</TableCell>
                  <TableCell className="tablecell-green">Stations depuis DB</TableCell>
                  <TableCell className="tablecell-green">Stations lignes depuis DB</TableCell>
                  <TableCell className="tablecell-green">Temps théoriques depuis SPOTI3</TableCell>
                  <TableCell className="tablecell-green">Nombre de retry renvoyés par SPOTI3</TableCell>
                  <TableCell className="tablecell-green">Nombre d&apos;erreurs renvoyés par SPOTI3</TableCell>
                </TableRow>
              </TableHead>
              {!isRequesting ? (
                <TableBody>
                  {data.spoti3.map(
                    ({
                      id,
                      dateImport,
                      dbStatus,
                      lineStatus,
                      stationStatus,
                      stationLineStatus,
                      timetableStatus,
                      spoti3Retries,
                      spoti3Errors
                    }) => (
                      <TableRow hover key={id}>
                        <TableCell>
                          <p style={{ fontWeight: 'bold' }}>{DateTime.fromISO(dateImport).toRelative()}</p>
                        </TableCell>
                        <TableCell>{getRightColorLabel(dbStatus)}</TableCell>
                        <TableCell>{getRightColorLabel(lineStatus)}</TableCell>
                        <TableCell>{getRightColorLabel(stationStatus)}</TableCell>
                        <TableCell>{getRightColorLabel(stationLineStatus)}</TableCell>
                        <TableCell>{getRightColorLabel(timetableStatus)}</TableCell>
                        <TableCell>{spoti3Retries}</TableCell>
                        <TableCell>{spoti3Errors}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow hover>
                    <TableCell colSpan={11} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>

      <Box sx={{ pt: 3, pb: 3 }}>
        <Button
          onClick={() => triggerImportDataSubsidiaries(subsidiaries.map((item) => item.agencyId))}
          disabled={isImportInProgress()}
          color="primary"
          variant="contained"
        >
          Re-Importer les données GTFS de tous les réseaux
        </Button>
      </Box>

      {Object.entries(mapSubsidiariesGtfsData()).map(([key, value]) => {
        return (
          <Card key={key} style={{ marginBottom: 30 }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <CardHeader title={`Status des imports GTFS ${getAgencyName(key)}`} />
              <Button
                color="primary"
                variant="contained"
                disabled={isImportInProgress()}
                onClick={() => reImport(key)}
                style={{ marginRight: 15 }}
              >
                importer
              </Button>
            </Box>
            <Divider />
            <PerfectScrollbar>
              <Box sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="tablecell-green">Date</TableCell>
                      <TableCell className="tablecell-green">Téléchargement GTFS </TableCell>
                      <TableCell className="tablecell-green">Import des données GTFS</TableCell>
                      <TableCell className="tablecell-green">Lignes</TableCell>
                      <TableCell className="tablecell-green">Stations</TableCell>
                      <TableCell className="tablecell-green">Lignes/Stations</TableCell>
                      <TableCell className="tablecell-green">Temps théoriques</TableCell>
                    </TableRow>
                  </TableHead>

                  {!isRequesting ? (
                    <TableBody>
                      {value.map(
                        ({
                          id,
                          dateImport,
                          gtfsDownloadStatus,
                          gtfsImportStatus,
                          lineStatus,
                          stationLineStatus,
                          stationStatus,
                          timetableStatus
                        }) => (
                          <TableRow hover key={id}>
                            <TableCell>
                              <p style={{ fontWeight: 'bold' }}>{DateTime.fromISO(dateImport).toRelative()}</p>
                            </TableCell>
                            <TableCell>{getRightColorLabel(gtfsDownloadStatus)}</TableCell>
                            <TableCell>{getRightColorLabel(gtfsImportStatus)}</TableCell>
                            <TableCell>{getRightColorLabel(lineStatus)}</TableCell>
                            <TableCell>{getRightColorLabel(stationStatus)}</TableCell>
                            <TableCell>{getRightColorLabel(stationLineStatus)}</TableCell>
                            <TableCell>{getRightColorLabel(timetableStatus)}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow hover>
                        <TableCell colSpan={11} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        );
      })}
    </Box>
  );
};

ImportsList.propTypes = {
  data: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool,
  subsidiaries: PropTypes.array.isRequired
};

export default ImportsList;
